import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import { BackLinkNav, BackLinkNavIconWrap, HeaderWrapper, LogoWrap, PageNameWrap, ShowSearchButton } from './Header.styled';

import useMatchMedia from '../../../../hooks/common/useMatchMedia';
import { useUpdateAddress } from '../../../../hooks/mutations/profile/useProfileMutations';
import { useCartQuery } from '../../../../hooks/queries/cart/useCartQuery';
import { useActiveAddressQuery, useProfileAddressQuery } from '../../../../hooks/queries/profile/useProfileQuery';
import { GEO } from '../../../../shared/const/common.const';
import { cn } from '../../../../shared/lib/classnames.helper';
import { useStore } from '../../../../store';
import { HeaderType } from '../../../../types/CommonTypes';
import { Container, FlexWithAlign } from '../../../../utils/styleUtils';
import { EIcon, Icon } from '../../../icons/icon';
import CommonDropdown from '../../dropdowns/common/CommonDropdown';
import SearchInput from '../../fields/SearchInput/SearchInput';
import MiniCart from '../libs/minicart/MiniCart';
import MobileMenu from '../libs/mobile-menu/MobileMenu';

const excludePages = ['/', '/wholesale-buyers', '/orders-history', '/myaccount'];

const Header: React.FC<HeaderType> = observer((props) => {
  const { pageStore } = props;
  const { pageName } = pageStore!;
  const { id, shopId } = useParams();
  const location = useLocation();
  const { modalStore } = useStore();
  const { data: addresses } = useProfileAddressQuery();
  const { data: address } = useActiveAddressQuery();

  const { mutate: updateAddress } = useUpdateAddress();
  const { data: cart, isLoading } = useCartQuery({ geo: address?.geo ? address?.geo : GEO });
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const [isLaptop] = useMatchMedia(['((max-width: 1280px))']);
  const [isTablet] = useMatchMedia(['((max-width: 970px))']);

  const [showSearch, setShowSearch] = useState<boolean>(true);
  const transformOptions = addresses?.map((address) => {
    return {
      value: address.id,
      label: address.value,
      active: address.active
    };
  });
  const openModal = () => {
    modalStore.openModal({
      modalName: 'ADD_ADDRESS'
    });
  };
  useEffect(() => {
    if (isTablet) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  }, [isTablet]);
  const AddAddressButton = ({ innerProps, innerRef }: any) => {
    const handleMouseDown = (event: any) => {
      event.preventDefault();
      openModal();
    };

    return (
      <FlexWithAlign
        onMouseDown={handleMouseDown}
        $align={'center'}
        $justify={'center'}
        $gap={'8px'}
        className={'add-address-button'}
        ref={innerRef}
        {...innerProps}
      >
        <Icon name={EIcon.plus} />
        <span>Добавить адрес</span>
      </FlexWithAlign>
    );
  };

  return (
    <HeaderWrapper>
      <Container className='header-container'>
        <FlexWithAlign
          $align='center'
          $gap='16px'
          className={cn('header-content', shopId && 'with-shop-name')}
        >
          <LogoWrap>
            <Link
              to={'/'}
              className='flex'
            >
              <Icon name={EIcon.logo} />
            </Link>
          </LogoWrap>
          <MobileMenu />
          {excludePages.findIndex((item) => item === location.pathname) === -1 && (
            <BackLinkNav>
              <Link
                className='back-link'
                to={
                  location.pathname.includes('/cart') && cart && cart && cart.shop
                    ? `/shops/${cart.shop.section_id}/catalog/${cart.shop.id}`
                    : '/'
                }
              >
                <BackLinkNavIconWrap>
                  <Icon name={EIcon.backarrow} />
                </BackLinkNavIconWrap>
                <span>{location.pathname.includes('/cart') ? 'В магазин' : 'Все рынки'}</span>
              </Link>
            </BackLinkNav>
          )}
          {shopId ? (
            <>
              {showSearch ? (
                <FlexWithAlign
                  className={'search-form search-form--header'}
                  $align={'center'}
                  $gap={'12px'}
                >
                  {isTablet && (
                    <ShowSearchButton
                      className={'hide-search-btn'}
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      <Icon name={EIcon.arrow} />
                    </ShowSearchButton>
                  )}

                  <form
                    className={cn(!id ? 'full' : 'small', 'search-form', 'mobile-search')}
                    action='/'
                  >
                    <SearchInput />
                  </form>
                </FlexWithAlign>
              ) : (
                <ShowSearchButton onClick={() => setShowSearch(!showSearch)}>
                  <Icon name={EIcon.search} />
                </ShowSearchButton>
              )}
            </>
          ) : (
            <form
              className={cn(!id ? 'full' : 'small', 'search-form')}
              action='/'
            >
              <SearchInput />
            </form>
          )}

          {shopId && <PageNameWrap className={'header-page-name'}>{pageName !== '' ? `«${pageName}»` : ''}</PageNameWrap>}
          {!isLaptop && transformOptions && (
            <CommonDropdown
              options={[...transformOptions, { value: 'add', label: <AddAddressButton /> }]}
              classNameWrap={'header-address-dropdown'}
              placeholder={'Адрес доставки'}
              labelTitle={'Адрес доставки'}
              customOption={AddAddressButton}
              currentValue={transformOptions.find((item) => item.active === true)?.value}
              onChange={(option: any) => {
                const foundAddress = addresses?.find((address) => address.id === option.value);
                updateAddress({
                  id: Number(option.value),
                  value: option.label,
                  geo: foundAddress ? { ...foundAddress?.geo } : { ...GEO },
                  description: 'Описание',
                  active: true
                });
              }}
            >
              <Icon name={EIcon.delivery} />
            </CommonDropdown>
          )}

          <MiniCart
            cart={cart}
            isLoading={isLoading}
          />
        </FlexWithAlign>
      </Container>
      {isLaptop && (
        <Container className='mobile-container'>
          {transformOptions && (
            <CommonDropdown
              options={[...transformOptions, { value: 'add', label: <AddAddressButton /> }]}
              classNameWrap={'header-address-dropdown'}
              placeholder={'Адрес доставки'}
              labelTitle={'Адрес доставки'}
              customOption={AddAddressButton}
              currentValue={transformOptions.find((item) => item.active === true)?.value}
              onChange={(option: any) => {
                const foundAddress = addresses?.find((address) => address.id === option.value);

                updateAddress({
                  id: Number(option.value),
                  value: option.label,
                  geo: foundAddress ? { ...foundAddress?.geo } : { ...GEO },
                  description: 'Описание',
                  active: true
                });
              }}
            >
              <Icon name={EIcon.delivery} />
            </CommonDropdown>
          )}
        </Container>
      )}
    </HeaderWrapper>
  );
});

export default inject('pageStore')(Header);
