import React, { useEffect, useState } from 'react';

import './styles/normalize.css';
import './styles/global.css';
import { AppRouter } from './app/providers/router/ui/AppRouter';
import { useTheme } from './app/providers/ThemeProvider';
import { getCookie, setCookie } from './api/cookies';
import { getSession } from './shared/services/userSessions.service';
import { useBodyScrollable } from './hooks/common/useBodyScrollable';
import { useLocation } from 'react-router-dom';
import { WebsocketProvider } from './context/WebsocketContext';

function App() {
  const { isTheme } = useTheme();
  const { pathname } = useLocation();
  const [userSession, setUserSession] = useState<string | undefined>(getCookie('user-session-fruktonos'));
  const handleGetSession = async () => {
    const res = await getSession();
    setCookie('user-session-fruktonos', res.id);
    setUserSession(res.id);
    return res;
  };
  useEffect(() => {
    const userSessionId = getCookie('user-session-fruktonos');

    if (!userSessionId && pathname !== '/auth' && pathname !== '/login') {
      handleGetSession();
    }
  }, [userSession]);

  const bodyScrollable = useBodyScrollable();
  // useLayoutEffect(() => {
  //   if (bodyScrollable) {
  //     document.body.style.paddingRight = '0px';
  //   } else {
  //     document.body.style.paddingRight = `${scrollbarWidth}px`;
  //   }
  // }, [bodyScrollable]);
  return (
    <div className={`App ${isTheme}`}>
      <WebsocketProvider>
        <AppRouter />
      </WebsocketProvider>
    </div>
  );
}

export default App;
