import React from 'react';
import { Link } from 'react-router-dom';

import { IconWrapper, MiniCartWrapper, MobileCartNotEmpty, PriceWrapper } from './MiniCart.styled';

import useMatchMedia from '../../../../../hooks/common/useMatchMedia';
import { IMiniCart } from '../../../../../types/CommonTypes';
import { EIcon, Icon } from '../../../../icons/icon';

const MiniCart: React.FC<IMiniCart> = (props) => {
  const { cart } = props;
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  return (
    <MiniCartWrapper className={'header-mini-cart'}>
      <Link to={'/cart'}>
        <IconWrapper>
          <Icon name={EIcon.cart} />
        </IconWrapper>
        {/* <PriceWrapper>{cart && cart.total ? (cart.total > 999 ? '999+ ₽' : `${Number(cart.total).toFixed(0)} ₽`) : '0 ₽'} </PriceWrapper> */}
        <PriceWrapper>{cart && cart.subtotal ? `${Number(cart.subtotal).toFixed(0)} ₽` : '0 ₽'} </PriceWrapper>
        {isMobile && cart && Number(cart.subtotal) > 0 && <MobileCartNotEmpty />}
      </Link>
    </MiniCartWrapper>
  );
};

export default MiniCart;
