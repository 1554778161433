import axios from 'axios';
import Cookies from 'js-cookie';

import { getCookie, setCookie } from './cookies';

import { getSession } from '../shared/services/userSessions.service';

const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
    'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS'
  }
});

instance.interceptors.request.use(async (config) => {
  const auth = getCookie('fruktonos-auth');
  const userSessionId = getCookie('user-session-fruktonos');
  if (auth) {
    config.headers['authorization'] = `Bearer ${auth}`;
  }
  if (userSessionId) {
    config.headers['x-frktns-session-id'] = userSessionId;
  }
  if (config.data && config.url === '/support-chat/send') {
    const { images, ...jsonPayload } = config.data;
    if (images) {
      const form = new FormData();

      Object.keys(jsonPayload).forEach((key) => {
        form.append(key, jsonPayload[key]);
      });

      for (const image of images) {
        form.append('images[]', image);
      }

      config.data = form;
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    if (error.response.status === 401 && window.location.pathname !== '/auth') {
      Cookies.remove('fruktonos-auth');
    }
    if (error.response.status === 403 || error.response.status === 500) {
      Cookies.remove('user-session-fruktonos');
      const userSession = await getSession();
      setCookie('user-session-fruktonos', userSession.id);
      const response = await instance.request(error.config);

      return response.data;
    }

    return error.response;
  }
);

export async function apiGet(url: string) {
  const response = await instance.get(`${url}`);

  return response;
}

export async function apiGetWithData(url: string, params: any, signal?: AbortSignal) {
  const response = await instance.get(`${url}`, { params: params, signal });

  return response;
}

export async function apiPost(url: string, payload: any) {
  const response = await instance.post(`${url}`, payload);

  return response;
}

export async function apiPostFormData(url: string, payload: any) {
  const response = await instance.post(`${url}`, payload);

  return response;
}

export async function apiPostWithParams(url: string, params: any) {
  const response = await instance.post(`${url}`, {}, { params });

  return response;
}
export async function apiPostWithDataParams(url: string, payload: any, params: any) {
  const response = await instance.post(`${url}`, payload, { params: params });

  return response;
}
export async function apiPut(url: string, payload: any) {
  const response = await instance.put(`${url}`, payload);

  return response;
}
export async function apiPatch(url: string, payload: any) {
  const response = await instance.patch(`${url}`, payload);

  return response;
}
export async function apiDelete(url: string) {
  const response = await instance.delete(`${url}`);

  return response;
}

export async function apiDeleteWithData(url: string, params: any) {
  const response = await instance.delete(`${url}`, { params: params });

  return response;
}
export default instance;
